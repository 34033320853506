import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../../../axios";
import imageUploader from "../../../../../utilitis/imageUploader/imageUploader";

const MenuCategoryCard = ({ menuCategory, isUpdate, setIsUpdate }) => {
  const { _id, category, catagoryImg, subCategory } = menuCategory;

  const [modal2, setModal2] = useState();
  const [image, setImage] = useState(null);
  const [imgUrl, setImgUrl] = useState(catagoryImg);
  const [btnActive, setBtnActive] = useState(false);

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const form = e.target;
    const categoryName = form.categoryName.value.toLowerCase();
    const categorySubCategories = form.categorySubCategories.value
      .toLowerCase()
      .split(",");

    const categoryImageUrl = imgUrl ? imgUrl : await imageUploader(image);

    const postData = {
      category: categoryName,
      catagoryImg: categoryImageUrl,
      subCategory: categorySubCategories,
    };

    try {
      await axios.put(`/menucategory/${_id}`, postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      toggle2();
      setIsUpdate(!isUpdate);
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/menucategory/${_id}`);
          Swal.fire("Deleted!", "Your item has been deleted.", "success");
          setIsUpdate(!isUpdate);
        } catch (error) {
          Swal.fire("Error!");
        }
      }
    });
  };

  return (
    <div className="col-sm-12 col-xl-4">
      <div className="card height-equal">
        <div className="card-body">
          <h3 className="text-capitalize">{category}</h3>
          <p>
            Sub Categories: <br />
            <span className="text-capitalize">{subCategory.join(", ")}</span>
          </p>
          <div
            style={{
              height: "200px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ height: "150px" }}
              className="img-fluid"
              src={catagoryImg}
              alt=""
            />
          </div>
        </div>
        <div className="card-footer">
          <Button onClick={toggle2} className="me-1" color="warning">
            Update
          </Button>
          <Button onClick={handleDelete} color="danger">
            Delete
          </Button>
        </div>
      </div>
      <Modal
        isOpen={modal2}
        toggle={toggle2}
        className="modal-body"
        centered={true}
      >
        <ModalHeader toggle={toggle2}>Update Menu Category</ModalHeader>
        <ModalBody>
          <div className="card">
            <form
              onSubmit={(e) => {
                setBtnActive(false);
                handleUpdate(e);
              }}
              className="form theme-form"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="categoryName">Category Name</label>
                      <input
                        className="form-control text-capitalize"
                        type="text"
                        id="categoryName"
                        placeholder="Category Name"
                        defaultValue={category}
                        required
                        onChange={(e) => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="categorySubCategories">
                        Sub Categories
                      </label>
                      <input
                        className="form-control text-capitalize"
                        type="text"
                        id="categorySubCategories"
                        placeholder="Sub Categories"
                        defaultValue={subCategory.join(", ")}
                        required
                        onChange={(e) => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-capitalize">
                        Upload Category Image
                      </label>
                      <div className="col-sm-9">
                        {imgUrl ? (
                          <div style={{ position: "relative" }}>
                            <img
                              style={{
                                width: "300px",
                                display: "block",
                                margin: "15px auto",
                              }}
                              src={imgUrl}
                              alt=""
                            />
                            <span
                              style={{
                                backgroundColor: "red",
                                border: "none",
                                borderRadius: "7px",
                                position: "absolute",
                                right: "0",
                                top: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => setImgUrl(null)}
                            >
                              <i
                                style={{
                                  color: "white",
                                  padding: "7px 7px",
                                  fontSize: "15px",
                                }}
                                className="fa fa-times"
                              ></i>
                            </span>
                          </div>
                        ) : (
                          <input
                            className="form-control"
                            type="file"
                            id="fileInput"
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                              setBtnActive(true);
                            }}
                            accept="image/*"
                            required
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  disabled={!btnActive}
                  className="me-2"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={toggle2}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MenuCategoryCard;
