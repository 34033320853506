import { File, Menu, Settings } from "react-feather";

export const MENUITEMS = [
  {
    title: "Pages",
    icon: File,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/pages/homepage", title: "Home Page", type: "link" },
      { path: "/pages/gallery", title: "Gallery", type: "link" },
      { path: "/pages/about", title: "About", type: "link" },
    ],
  },
  {
    title: "Menu",
    icon: Menu,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/menu/menucategory", title: "Menu Category", type: "link" },
      { path: "/menu/menulist", title: "Menu List", type: "link" },
    ],
  },
  {
    title: "Settings",
    icon: Settings,
    type: "link",
    badgeType: "primary",
    active: false,
    path: "/settings",
  },
];
