import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../axios";
import imageUploader from "../../../utilitis/imageUploader/imageUploader";
import Breadcrumb from "../../common/breadcrumb";

const Settings = () => {
  const [data, setData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [modal2, setModal2] = useState();
  const [logo, setLogo] = useState(null);
  const [favIcon, setFavIcon] = useState(null);
  const [lgUrl, setLgUrl] = useState();
  const [fIcUrl, setFIcUrl] = useState();
  const [btnActive, setBtnActive] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("/setting");
        console.log(response?.data?.settingData);
        setData(response?.data?.settingData[0]);
        setLgUrl(response?.data?.settingData[0]?.logoUrl);
        setFIcUrl(response?.data?.settingData[0]?.favIconUrl);
      } catch (error) {
        console.error("Error", error);
      }
    };

    getData();
  }, [isUpdate]);

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const companyName = form.companyName.value;
    const slogan = form.slogan.value;
    const openingHours = form.openingHours.value;
    const addresses = form.addresses.value
      .split(",")
      .map((item) => item.trim());
    const socialLinks = form.socialLinks.value
      .split(",")
      .map((item) => item.trim());
    const phones = form.phones.value.split(",").map((item) => item.trim());
    const emails = form.emails.value.split(",").map((item) => item.trim());

    const logoUrl = lgUrl ? lgUrl : await imageUploader(logo);
    const favIconUrl = fIcUrl ? fIcUrl : await imageUploader(favIcon);

    const postData = {
      companyName,
      slogan,
      openingHours,
      addresses,
      socialLinks,
      phones,
      emails,
      logoUrl,
      favIconUrl,
    };

    console.log(postData);

    try {
      await axios.put("/setting", postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsUpdate(!isUpdate);
      toggle2();
    } catch (error) {
      alert("Error submitting data.");
      setBtnActive(true);
    }
  };

  return (
    <div>
      <Breadcrumb parent="Settings" title="Settings" />
      <div className="mb-2">
        <Button color="primary" onClick={toggle2}>
          Update
        </Button>
      </div>
      <div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Logo </h5>
                <div style={{ height: "100px", textAlign: "center" }}>
                  {data?.logoUrl && (
                    <img
                      style={{ height: "100%" }}
                      className="image-fluid"
                      src={data?.logoUrl}
                      alt="logo"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Icon </h5>
                <div style={{ height: "100px", textAlign: "center" }}>
                  {data?.favIconUrl && (
                    <img
                      style={{ height: "100%" }}
                      className="image-fluid"
                      src={data?.favIconUrl}
                      alt="icon"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Company Name</h5>
                <div style={{ height: "100px", textAlign: "center" }}>
                  <p style={{ fontSize: "2em" }}>{data?.companyName}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Slogan </h5>
                <div style={{ height: "100px", textAlign: "center" }}>
                  <p>{data?.slogan}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Opening Hours </h5>
                <div style={{ height: "100px", textAlign: "center" }}>
                  {data?.openingHours}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Addresses </h5>
                <div style={{ minHeight: "100px" }}>
                  {data?.addresses?.map((address, index) => (
                    <li key={index}>{address}</li>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Social Links </h5>
                <div style={{ minHeight: "100px" }}>
                  {data?.socialLinks?.map((socialLink, index) => (
                    <li key={index}>{socialLink}</li>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Phones</h5>
                <div style={{ height: "100px" }}>
                  {data?.phones?.map((phone, index) => (
                    <li key={index}>{phone}</li>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5>Emails</h5>
                <div style={{ height: "100px" }}>
                  {data?.emails?.map((email, index) => (
                    <li key={index}>{email}</li>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          isOpen={modal2}
          toggle={toggle2}
          className="modal-body"
          centered={true}
        >
          <ModalHeader toggle={toggle2}>Settings</ModalHeader>
          <ModalBody>
            <div className="card">
              <div className="card-header">
                <h5>Update Settings</h5>
              </div>
              <form
                onSubmit={(e) => {
                  setBtnActive(false);
                  handleSubmit(e);
                }}
                className="form theme-form"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="text-capitalize"
                          htmlFor="companyName"
                        >
                          Company Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="companyName"
                          defaultValue={data?.companyName}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="slogan">
                          Slogan
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          id="slogan"
                          defaultValue={data?.slogan}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="text-capitalize"
                          htmlFor="openingHours"
                        >
                          Opening Hours
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="openingHours"
                          defaultValue={data?.openingHours}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="addresses">
                          Addresses
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          id="addresses"
                          defaultValue={data?.addresses?.join(", ")}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="text-capitalize"
                          htmlFor="socialLinks"
                        >
                          Social Links
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          id="socialLinks"
                          defaultValue={data?.socialLinks?.join(", ")}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="phones">
                          Phones
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="phones"
                          defaultValue={data?.phones?.join(", ")}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="emails">
                          Emails
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="emails"
                          defaultValue={data?.emails?.join(", ")}
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-capitalize">
                          Upload Logo
                        </label>
                        <div className="col-sm-9">
                          {lgUrl ? (
                            <div style={{ position: "relative" }}>
                              <img
                                style={{
                                  width: "300px",
                                  display: "block",
                                  margin: "15px auto",
                                }}
                                src={lgUrl}
                                alt=""
                              />
                              <span
                                style={{
                                  backgroundColor: "red",
                                  border: "none",
                                  borderRadius: "7px",
                                  position: "absolute",
                                  right: "0",
                                  top: "0",
                                  cursor: "pointer",
                                }}
                                onClick={() => setLgUrl(null)}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    padding: "7px 7px",
                                    fontSize: "15px",
                                  }}
                                  className="fa fa-times"
                                ></i>
                              </span>
                            </div>
                          ) : (
                            <input
                              className="form-control"
                              type="file"
                              id="fileInput"
                              onChange={(e) => {
                                setLogo(e.target.files[0]);
                                setBtnActive(true);
                              }}
                              accept="image/*"
                              required
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-capitalize">
                          Upload Icon
                        </label>
                        <div className="col-sm-9">
                          {fIcUrl ? (
                            <div style={{ position: "relative" }}>
                              <img
                                style={{
                                  width: "300px",
                                  display: "block",
                                  margin: "15px auto",
                                }}
                                src={fIcUrl}
                                alt=""
                              />
                              <span
                                style={{
                                  backgroundColor: "red",
                                  border: "none",
                                  borderRadius: "7px",
                                  position: "absolute",
                                  right: "0",
                                  top: "0",
                                  cursor: "pointer",
                                }}
                                onClick={() => setFIcUrl(null)}
                              >
                                <i
                                  style={{
                                    color: "white",
                                    padding: "7px 7px",
                                    fontSize: "15px",
                                  }}
                                  className="fa fa-times"
                                ></i>
                              </span>
                            </div>
                          ) : (
                            <input
                              className="form-control"
                              type="file"
                              id="fileInput"
                              onChange={(e) => {
                                setFavIcon(e.target.files[0]);
                                setBtnActive(true);
                              }}
                              accept="image/*"
                              required
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    disabled={!btnActive}
                    className="me-2"
                    type="submit"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggle2}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default Settings;
