import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../axios";

const GalleryImage = ({ index, image, id, isUpdate, setIsUpdate }) => {
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/gallery/${id}`);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          setIsUpdate(!isUpdate);
        } catch (error) {
          Swal.fire("Error!");
        }
      }
    });
  };

  return (
    <div className="col-sm-12 col-xl-4">
      <div className="card height-equal">
        <div className="card-header">
          <h5>{index + 1}</h5>
        </div>
        <div className="card-body">
          <div style={{ height: "200px", textAlign: "center" }}>
            <img
              style={{
                backgroundSize: "cover",
                height: "100%",
              }}
              src={image}
              alt={index}
              className="img-fluid"
            />
          </div>
        </div>
        <div style={{ textAlign: "right" }} className="card-footer">
          <Button onClick={() => handleDelete(id)} color="danger">
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GalleryImage;
