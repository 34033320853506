import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../axios";
import imageUploader from "../../../utilitis/imageUploader/imageUploader";
import Breadcrumb from "../../common/breadcrumb";
import GalleryImage from "./GalleryImage";

const Gallery = () => {
  const [data, setData] = useState([]);
  const [modal2, setModal2] = useState();
  const [image, setImage] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [btnActive, setBtnActive] = useState(false);

  const toggle2 = () => {
    setModal2(!modal2);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("/gallery");
        console.log(response.data.GalleryData);
        setData(response.data.GalleryData);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };

    getData();
  }, [isUpdate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //image uploader function
    const imageUrl = await imageUploader(image);

    // Get the WebP format URL from the Cloudinary response

    console.log(imageUrl);

    const postData = { image: imageUrl };
    console.log("object:", postData);

    try {
      await axios.post("/gallery", postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsUpdate(!isUpdate);
      toggle2();
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  return (
    <div>
      <Breadcrumb parent="Pages" title="Gallery" />
      <Button className="mb-3" color="primary" onClick={toggle2}>
        Add New Image
      </Button>
      <Modal
        isOpen={modal2}
        toggle={toggle2}
        className="modal-body"
        centered={true}
      >
        <ModalHeader toggle={toggle2}>Gallery</ModalHeader>
        <ModalBody>
          <div className="card">
            <div className="card-header">
              <h5>Image Uploader</h5>
            </div>
            <form
              onSubmit={(e) => {
                setBtnActive(false);
                handleSubmit(e);
              }}
              className="form theme-form"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-capitalize">
                        Upload An Image
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="file"
                          id="fileInput"
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                            setBtnActive(true);
                          }}
                          accept="image/*"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  disabled={!btnActive}
                  className="me-2"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={toggle2}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      {data.length ? (
        <div className="row">
          {data.map((image, index) => (
            <GalleryImage
              key={image._id}
              image={image.image}
              index={index}
              id={image._id}
              isUpdate={isUpdate}
              setIsUpdate={setIsUpdate}
            />
          ))}
        </div>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Gallery;
