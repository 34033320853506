import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../../axios";
import imageUploader from "../../../../utilitis/imageUploader/imageUploader";
import Breadcrumb from "../../../common/breadcrumb";
import MenuListCard from "./MenuListCard/MenuListCard";

const MenuList = () => {
  const [data, setData] = useState([]);
  const [modal2, setModal2] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [btnActive, setBtnActive] = useState(false);
  const [isOffer, setIsOffer] = useState(false);

  const toggle2 = () => {
    setModal2(!modal2);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("/menu");
        console.log(response.data.allMenuData);
        setData(response.data.allMenuData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getData();
  }, [isUpdate]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get("/menucategory");
        console.log(response.data.allMenuCategoryData);
        setCategories(response.data.allMenuCategoryData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getCategory();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const name = form.name.value.toLowerCase();
    const description = form.description.value;
    const category = form.category.value.toLowerCase();
    const subCategory = form.subCategory.value.toLowerCase();
    const price = form.price.value;
    const isOffer = form.offer.value;

    const imageUrl = await imageUploader(image);

    const postData = {
      name,
      description,
      category,
      subCategory,
      price,
      isOffer,
      img: imageUrl,
    };

    console.log(postData);

    try {
      await axios.post("/menu", postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      toggle2();
      setIsUpdate(!isUpdate);
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  return (
    <div>
      <Breadcrumb parent="Menu" title="Menu List" />
      <div>
        <Button className="mb-3" color="primary" onClick={toggle2}>
          Add Item
        </Button>
        {/* <div style={{ textAlign: "right" }} className="mb-3">
          {categories.map((category) => (
            <Button color="info" outline className="text-capitalize ms-2">
              {category.category}
            </Button>
          ))}
        </div> */}
        <Modal
          isOpen={modal2}
          toggle={toggle2}
          className="modal-body"
          centered={true}
        >
          <ModalHeader toggle={toggle2}>Add A Item</ModalHeader>
          <ModalBody>
            <div className="card">
              <form
                onSubmit={(e) => {
                  setBtnActive(false);
                  handleSubmit(e);
                }}
                className="form theme-form"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          id="name"
                          placeholder="Name"
                          required
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                          className="form-control"
                          id="description"
                          placeholder="Description"
                          type="text"
                          required
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="category">Category</label>
                        <select
                          className="custom-select form-control text-capitalize"
                          name="category"
                          required
                          onChange={(e) => {
                            setSubCategories(
                              categories?.find(
                                (category) =>
                                  category?.category === e.target.value
                              )?.subCategory
                            );
                            setBtnActive(true);
                          }}
                        >
                          <option value="">
                            {"Open this select category *"}
                          </option>
                          {categories?.map((category) => (
                            <option
                              key={category._id}
                              defaultValue={category?.category}
                              className="text-capitalize"
                            >
                              {category?.category}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="subCategory">Sub Category</label>
                        <select
                          className="custom-select form-control text-capitalize"
                          name="subCategory"
                          required
                          onChange={(e) => setBtnActive(true)}
                        >
                          <option value="">
                            {"Open this select subCategory *"}
                          </option>
                          {subCategories?.map((subCategory, index) => (
                            <option
                              key={index}
                              defaultValue={subCategory}
                              className="text-capitalize"
                            >
                              {subCategory}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="price">Price</label>
                        <input
                          className="form-control"
                          id="price"
                          type="text"
                          placeholder="Price"
                          required
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                        }}
                        className="form-group"
                      >
                        <label htmlFor="offer">Offer</label>
                        <input
                          id="offer"
                          type="checkbox"
                          name="offer"
                          value={isOffer}
                          onClick={(e) => {
                            setIsOffer(!isOffer);
                            setBtnActive(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-capitalize">
                          Upload Item Image
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="file"
                            id="fileInput"
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                              setBtnActive(true);
                            }}
                            accept="image/*"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    disabled={!btnActive}
                    className="me-2"
                    type="submit"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggle2}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <div className="row">
        {data.map((item) => (
          <MenuListCard
            key={item._id}
            item={item}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
          />
        ))}
      </div>
    </div>
  );
};

export default MenuList;
