import React from "react";
import Swal from "sweetalert2";
import axios from "../../../../../axios";
import UpdateSlider from "../UpdateSlider/UpdateSlider";

const Slider = ({ slider, index, isUpdate, setIsUpdate }) => {
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/slider/${id}`);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          setIsUpdate(!isUpdate);
        } catch (error) {
          Swal.fire("Error!");
        }
      }
    });
  };

  return (
    <tr>
      <th>{index + 1}</th>
      <th>{slider?.title}</th>
      <td>{slider?.subTitle}</td>
      <td>{slider?.tag}</td>
      <td>
        <img
          style={{ height: "32px", width: "32px", borderRadius: "5px" }}
          src={slider?.backgroundImageUrl}
          alt={index + 1}
        />
      </td>
      <td>
        {slider?.sideImageUrl ? (
          <img
            style={{ height: "32px", width: "32px", borderRadius: "5px" }}
            src={slider?.sideImageUrl}
            alt={index + 1}
          />
        ) : (
          <span>N/A</span>
        )}
      </td>
      <td style={{ minWidth: "100px" }}>
        <UpdateSlider
          slider={slider}
          index={index}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
        />
        <button
          style={{
            backgroundColor: "red",
            border: "none",
            borderRadius: "5px",
            marginLeft: "5px",
          }}
          onClick={() => handleDelete(slider?._id)}
        >
          <i
            style={{
              color: "white",
              padding: "7px 3px",
              fontSize: "15px",
            }}
            className="fa fa-trash-o"
          ></i>
        </button>
      </td>
    </tr>
  );
};

export default Slider;
