import About from "../components/pages/AboutUs/About";
import Gallery from "../components/pages/Gallery/Gallery";
import Homepage from "../components/pages/Homepage/Homepage";
import MenuCategory from "../components/pages/Menu/MenuCategory/MenuCategory";
import MenuList from "../components/pages/Menu/MenuList/MenuList";
import Settings from "../components/pages/Settings/Settings";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/pages/homepage`,
    Component: <Homepage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/gallery`,
    Component: <Gallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/about`,
    Component: <About />,
  },
  {
    path: `${process.env.PUBLIC_URL}/menu/menucategory`,
    Component: <MenuCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/menu/menulist`,
    Component: <MenuList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings`,
    Component: <Settings />,
  },
];
