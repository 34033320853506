import React, { Fragment, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../../../axios";
import imageUploader from "../../../../../utilitis/imageUploader/imageUploader";

const AddSlider = ({ isUpdate, setIsUpdate }) => {
  const [modal, setModal] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [sideImage, setSideImage] = useState(null);
  const [btnActive, setBtnActive] = useState(false);

  const toggle2 = () => {
    setModal(!modal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const title = form.title.value;
    const subTitle = form.subTitle.value;
    const tag = form.tag.value;

    console.log(backgroundImage, sideImage);

    const backgroundImageUrl = await imageUploader(backgroundImage);
    const sideImageUrl = await imageUploader(sideImage);

    const postData = { title, subTitle, tag, backgroundImageUrl, sideImageUrl };

    try {
      await axios.post("/slider", postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      toggle2();
      setIsUpdate(!isUpdate);
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  return (
    <Fragment>
      <Button color="primary" onClick={toggle2}>
        Add New Slider
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle2}
        className="modal-body"
        centered={true}
      >
        <ModalBody>
          <d className="card">
            <div className="card-header">
              <h5>Slide Uploader</h5>
            </div>
            <form
              onSubmit={(e) => {
                setBtnActive(false);
                handleSubmit(e);
              }}
              className="form theme-form"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="title">
                        Title
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="title"
                        required
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="title">
                        Subtitle
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="subTitle"
                        required
                        onChange={() => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label className="text-capitalize" htmlFor="tag">
                        Tag
                      </label>
                      <input className="form-control" id="tag" required />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-capitalize">
                        Upload Background Image
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="file"
                          id="fileInput"
                          onChange={(e) => {
                            setBackgroundImage(e.target.files[0]);
                            setBtnActive(true);
                          }}
                          accept="image/*"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-capitalize">
                        Upload Side Image
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="file"
                          id="fileInput"
                          onChange={(e) => {
                            setSideImage(e.target.files[0]);
                            setBtnActive(true);
                          }}
                          accept="image/*"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  disabled={!btnActive}
                  className="me-2"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={toggle2}>
                  Cancel
                </Button>
              </div>
            </form>
          </d>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddSlider;
