import { useEffect, useState } from "react";
import CKEditors from "react-ckeditor-component";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../axios";
import imageUploader from "../../../utilitis/imageUploader/imageUploader";
import Breadcrumb from "../../common/breadcrumb";

const About = () => {
  const [data, setData] = useState({});
  const [modal2, setModal2] = useState();
  const [image, setImage] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [content, setContent] = useState();
  const [btnActive, setBtnActive] = useState(false);

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setContent(newContent);
    setBtnActive(true);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("/about");
        console.log(response.data.aboutData);
        setData(response.data.aboutData[0]);
        setImgUrl(response.data.aboutData[0].imageUrl);
        setContent(response.data.aboutData[0].description);
      } catch (error) {
        console.error("Error", error);
      }
    };

    getData();
  }, [isUpdate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const title = form.title.value;

    const description = content;

    //image uploader function
    const imageUrl = imgUrl ? imgUrl : await imageUploader(image);

    // Get the WebP format URL from the Cloudinary response

    console.log(imageUrl);

    const postData = { title, description, imageUrl };
    console.log("object:", postData);

    try {
      await axios.put("/about", postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      setIsUpdate(!isUpdate);
      toggle2();
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  return (
    <div>
      <Breadcrumb parent="Pages" title="About" />
      <Button className="mb-3" color="primary" onClick={toggle2}>
        Update
      </Button>
      <div className="col-sm-12 col-xl-12">
        <div className="card height-equal">
          <div className="card-header">
            <h5 className="text-center">{data?.title}</h5>
          </div>
          <div className="card-body">
            <div className="text-center" style={{ height: "200px" }}>
              <img
                style={{ height: "200px" }}
                src={data?.imageUrl}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="card-footer">
            <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          isOpen={modal2}
          toggle={toggle2}
          className="modal-body"
          centered={true}
        >
          <ModalHeader toggle={toggle2}>About</ModalHeader>
          <ModalBody>
            <div className="card">
              <div className="card-header">
                <h5>Update About</h5>
              </div>
              <form
                onSubmit={(e) => {
                  setBtnActive(false);
                  handleSubmit(e);
                }}
                className="form theme-form"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="text-capitalize" htmlFor="title">
                          Title
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="title"
                          defaultValue={data?.title}
                          required
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label
                          className="text-capitalize"
                          htmlFor="description"
                        >
                          Description
                        </label>
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-body">
                                  <CKEditors
                                    activeclassName="p10"
                                    content={content}
                                    events={{
                                      change: onChange,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-capitalize">
                          Upload Image
                        </label>
                        <div className="col-sm-9">
                          <div className="col-sm-9">
                            {imgUrl ? (
                              <div style={{ position: "relative" }}>
                                <img
                                  style={{
                                    width: "300px",
                                    display: "block",
                                    margin: "15px auto",
                                  }}
                                  src={imgUrl}
                                  alt=""
                                />
                                <span
                                  style={{
                                    backgroundColor: "red",
                                    border: "none",
                                    borderRadius: "7px",
                                    position: "absolute",
                                    right: "0",
                                    top: "0",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setImgUrl(null)}
                                >
                                  <i
                                    style={{
                                      color: "white",
                                      padding: "7px 7px",
                                      fontSize: "15px",
                                    }}
                                    className="fa fa-times"
                                  ></i>
                                </span>
                              </div>
                            ) : (
                              <input
                                className="form-control"
                                type="file"
                                id="fileInput"
                                onChange={(e) => {
                                  setImage(e.target.files[0]);
                                  setBtnActive(true);
                                }}
                                accept="image/*"
                                required
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    disabled={!btnActive}
                    className="me-2"
                    type="submit"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggle2}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default About;
