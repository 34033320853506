import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../../../axios";
import imageUploader from "../../../../../utilitis/imageUploader/imageUploader";

const MenuListCard = ({ item, isUpdate, setIsUpdate }) => {
  const { _id, img, subCategory, name, description, category, price, isOffer } =
    item;

  const [modal2, setModal2] = useState();
  const [image, setImage] = useState(null);
  const [imgUrl, setImgUrl] = useState(img);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [btnActive, setBtnActive] = useState(false);
  const [isOfferState, setIsOfferState] = useState(
    isOffer === "true" ? true : false
  );

  const toggle2 = () => {
    setModal2(!modal2);
  };

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get("/menucategory");
        console.log(response.data.allMenuCategoryData);
        setCategories(response.data.allMenuCategoryData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getCategory();
  }, []);

  useEffect(() => {
    setSubCategories(
      categories?.find((ctg) => ctg?.category === category)?.subCategory
    );
  }, [categories, category]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const name = form.name.value.toLowerCase();
    const description = form.description.value;
    const category = form.category.value.toLowerCase();
    const subCategory = form.subCategory.value.toLowerCase();
    const price = form.price.value;
    const isOffer = form.offer.value;

    const imageUrl = imgUrl ? imgUrl : await imageUploader(image);

    const postData = {
      name,
      description,
      category,
      price,
      subCategory,
      isOffer,
      img: imageUrl,
    };

    try {
      await axios.put(`/menu/${_id}`, postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      toggle2();
      setIsUpdate(!isUpdate);
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/menu/${_id}`);
          Swal.fire("Deleted!", "Your item has been deleted.", "success");
          setIsUpdate(!isUpdate);
        } catch (error) {
          Swal.fire("Error!");
        }
      }
    });
  };

  return (
    <div className="col-sm-12 col-xl-4">
      <div className="card height-equal">
        <div className="card-header">
          <h5>{name}</h5>
        </div>
        <div className="card-body">
          <div style={{ height: "200px", textAlign: "center" }}>
            <img
              style={{ height: "150px" }}
              className="img-fluid"
              src={img}
              alt=""
            />
          </div>
          <p>
            Description
            <br />
            <span style={{ height: "50px" }}>{description}</span>
          </p>
          <p className="text-capitalize">Category: {category}</p>
          <p className="text-capitalize">Sub Category: {subCategory}</p>
          <p>Price: {price}</p>
          <p>Offer: {isOffer ? "Yes" : "No"}</p>
        </div>
        <div className="card-footer">
          <Button onClick={toggle2} className="me-1" color="warning">
            Update
          </Button>
          <Button onClick={handleDelete} color="danger">
            Delete
          </Button>
        </div>
      </div>
      <Modal
        isOpen={modal2}
        toggle={toggle2}
        className="modal-body"
        centered={true}
      >
        <ModalHeader toggle={toggle2}>Update Item</ModalHeader>
        <ModalBody>
          <div className="card">
            <form
              onSubmit={(e) => {
                setBtnActive(false);
                handleSubmit(e);
              }}
              className="form theme-form"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        className="form-control text-capitalize"
                        type="text"
                        id="name"
                        placeholder="name"
                        defaultValue={name}
                        required
                        onChange={(e) => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        placeholder="description"
                        defaultValue={description}
                        type="text"
                        required
                        onChange={(e) => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="category">Category</label>
                      <select
                        className="custom-select form-control text-capitalize"
                        name="category"
                        required
                        defaultValue={category}
                        onChange={(e) => {
                          setSubCategories(
                            categories?.find(
                              (category) =>
                                category?.category === e.target.value
                            )?.subCategory
                          );
                          setBtnActive(true);
                        }}
                      >
                        <option value="">
                          {"Open this select category *"}
                        </option>
                        {categories?.map((category) => (
                          <option
                            key={category._id}
                            defaultValue={category?.category}
                            className="text-capitalize"
                          >
                            {category?.category}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="subCategory">Sub Category</label>
                      <select
                        className="custom-select form-control text-capitalize"
                        name="subCategory"
                        defaultValue={subCategory}
                        required
                        onChange={(e) => setBtnActive(true)}
                      >
                        <option value="">
                          {"Open this select subCategory *"}
                        </option>
                        {subCategories?.map((subCategory, index) => (
                          <option
                            key={index}
                            defaultValue={subCategory}
                            className="text-capitalize"
                          >
                            {subCategory}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="price">Price</label>
                      <input
                        className="form-control"
                        id="price"
                        type="text"
                        placeholder="price"
                        defaultValue={price}
                        required
                        onChange={(e) => setBtnActive(true)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                      className="form-group"
                    >
                      <label htmlFor="offer">Offer</label>
                      <input
                        id="offer"
                        type="checkbox"
                        name="offer"
                        checked={isOfferState}
                        value={isOfferState}
                        onClick={(e) => {
                          setIsOfferState(!isOfferState);
                          setBtnActive(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label text-capitalize">
                        Upload Item Image
                      </label>
                      <div className="col-sm-9">
                        {imgUrl ? (
                          <div style={{ position: "relative" }}>
                            <img
                              style={{
                                width: "300px",
                                display: "block",
                                margin: "15px auto",
                              }}
                              src={imgUrl}
                              alt=""
                            />
                            <span
                              style={{
                                backgroundColor: "red",
                                border: "none",
                                borderRadius: "7px",
                                position: "absolute",
                                right: "0",
                                top: "0",
                                cursor: "pointer",
                              }}
                              onClick={() => setImgUrl(null)}
                            >
                              <i
                                style={{
                                  color: "white",
                                  padding: "7px 7px",
                                  fontSize: "15px",
                                }}
                                className="fa fa-times"
                              ></i>
                            </span>
                          </div>
                        ) : (
                          <input
                            className="form-control"
                            type="file"
                            id="fileInput"
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                              setBtnActive(true);
                            }}
                            accept="image/*"
                            required
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  disabled={!btnActive}
                  className="me-2"
                  type="submit"
                  color="primary"
                >
                  Save
                </Button>
                <Button color="secondary" onClick={toggle2}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MenuListCard;
