import { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "../../../../axios";
import AddSlider from "./AddSlider/AddSlider";
import Slider from "./Slider/Slider";

const Sliders = () => {
  const [sliders, setSliders] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getSliders = async () => {
      try {
        const res = await axios.get("/slider");
        setSliders(res.data.allSliderData);
      } catch (err) {
        console.log(err);
      }
    };

    getSliders();
  }, [isUpdate]);

  return (
    <div>
      <h2>Sliders</h2>
      <div className="mb-3">
        <AddSlider isUpdate={isUpdate} setIsUpdate={setIsUpdate} />
      </div>
      {sliders.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Subtitle</th>
              <th scope="col">Tag</th>
              <th scope="col">Background Image</th>
              <th scope="col">Side Image</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {sliders?.map((slider, index) => (
              <Slider
                key={slider?._id}
                slider={slider}
                index={index}
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Sliders;
