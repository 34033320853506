import Breadcrumb from "../../common/breadcrumb";
import Sliders from "./Sliders/Sliders";

const Homepage = () => {
  return (
    <div>
      <Breadcrumb parent="Pages" title="Home Page" />
      <Sliders />
    </div>
  );
};

export default Homepage;
