import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import axios from "../../../../axios";
import imageUploader from "../../../../utilitis/imageUploader/imageUploader";
import Breadcrumb from "../../../common/breadcrumb";
import MenuCategoryCard from "./MenuCategoryCard/MenuCategoryCard";

const MenuCategory = () => {
  const [data, setData] = useState([]);
  const [modal2, setModal2] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [image, setImage] = useState(null);
  const [btnActive, setBtnActive] = useState(false);

  const toggle2 = () => {
    setModal2(!modal2);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get("/menucategory");
        console.log(response.data.allMenuCategoryData);
        setData(response.data.allMenuCategoryData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getData();
  }, [isUpdate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const categoryName = form.categoryName.value.toLowerCase();
    const categorySubCategories = form.categorySubCategories.value
      .toLowerCase()
      .split(",");

    const categoryImageUrl = await imageUploader(image);

    const postData = {
      category: categoryName,
      catagoryImg: categoryImageUrl,
      subCategory: categorySubCategories,
    };

    console.log(postData);

    try {
      await axios.post("/menucategory", postData);
      Swal.fire({
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500,
      });
      toggle2();
      setIsUpdate(!isUpdate);
    } catch (error) {
      alert("Error submitting data.");
    }
  };

  return (
    <div>
      <Breadcrumb parent="Menu" title="Menu Category" />
      <div>
        <Button className="mb-3" color="primary" onClick={toggle2}>
          Add Menu Category
        </Button>
        <Modal
          isOpen={modal2}
          toggle={toggle2}
          className="modal-body"
          centered={true}
        >
          <ModalHeader toggle={toggle2}>Add A Menu Category</ModalHeader>
          <ModalBody>
            <div className="card">
              <form
                onSubmit={(e) => {
                  setBtnActive(false);
                  handleSubmit(e);
                }}
                className="form theme-form"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="categoryName">Category Name</label>
                        <input
                          className="form-control"
                          type="text"
                          id="categoryName"
                          placeholder="Category Name"
                          required
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="categorySubCategories">
                          Sub Categories
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="categorySubCategories"
                          placeholder="Sub Categories"
                          required
                          onChange={(e) => setBtnActive(true)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-capitalize">
                          Upload Category Image
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="file"
                            id="fileInput"
                            onChange={(e) => {
                              setImage(e.target.files[0]);
                              setBtnActive(true);
                            }}
                            accept="image/*"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button
                    disabled={!btnActive}
                    className="me-2"
                    type="submit"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={toggle2}>
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </ModalBody>
        </Modal>
      </div>
      {data.length ? (
        <div className="row">
          {data.map((menuCategory) => (
            <MenuCategoryCard
              key={menuCategory._id}
              menuCategory={menuCategory}
              isUpdate={isUpdate}
              setIsUpdate={setIsUpdate}
            />
          ))}
        </div>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default MenuCategory;
